import { ApexOptions } from "apexcharts";

const formatDate = (value: string, includeTime = false) => {
  const date = new Date(value);

  const options: Intl.DateTimeFormatOptions = {
    month: "short", // This will return the short month name like "Sep"
  };

  if (includeTime) {
    options.hour = "2-digit";
    options.minute = "2-digit";
  }

  return date.toLocaleDateString(undefined, options);
};

export const getAreaChartOptionsDual = (
  categories: any,
  id: string
): ApexOptions => {
  return {
    chart: {
      id,
      type: "area",
      toolbar: {
        show: true,
        offsetX: -5,
        offsetY: -35,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          customIcons: [],
        },
        autoSelected: "zoom",
      },
      zoom: {
        enabled: true,
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
      },
    },
    colors: ["#0051A626"], // Set the color of the line
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories,
      tickAmount: 7,
      labels: {
        rotate: 0,
        formatter: (value) => formatDate(value),
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: [
      {
        labels: {
          formatter: (value: number) => `${value.toFixed(2)} ETH`,
        },
      },
      {
        opposite: true,
        labels: {
          formatter: (value: number) => {
            const formatter = new Intl.NumberFormat("en-US", {
              notation: "compact",
              minimumFractionDigits: 1,
              maximumFractionDigits: 1,
              style: "currency",
              currency: "USD",
            });
            return formatter.format(value);
          },
        },
      },
    ],
    tooltip: {
      x: {
        show: true,
        formatter: (value) => {
          const date = new Date(categories?.[value - 1] || "");
          return date?.toLocaleDateString();
        },
      },
    },
    grid: {
      strokeDashArray: 4,
    },
    stroke: {
      curve: "smooth",
      colors: ["#0051A6"],
      width: 3,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        shadeIntensity: 1,
        type: "vertical",
        gradientToColors: ["white"],
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 100],
        inverseColors: false,
      },
    },
  };
};
